import { createApp } from 'vue'
import App from './App.vue'

import createRouter from './router'
import createStore from './store'

import { createApolloProvider } from '@vue/apollo-option'
import { createApolloClient } from './apollo'

import { createI18n } from 'vue-i18n'
import i18nMessages from './i18n/messages'

import { Settings } from 'luxon'

import 'bulma/css/bulma.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowLeft,
  faBan,
  faCalendar,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronUp,
  faClock,
  faCoins,
  faEdit,
  faEllipsisH,
  faFile,
  faFileArchive,
  faFileDownload,
  faFileImage,
  faFileInvoiceDollar,
  faFilePdf,
  faFileUpload,
  faFolderOpen,
  faGears,
  faKeyboard,
  faPaperclip,
  faPercent,
  faPlus,
  faPrint,
  faTimeline,
  faTimes,
  faTrashAlt,
  faUsers,
  faWeightScale,
} from '@fortawesome/free-solid-svg-icons'

const faTrash = faTrashAlt
faTrash.iconName = 'trash'

// Font Awsome
library.add(
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowLeft,
  faBan,
  faCalendar,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronUp,
  faClock,
  faCoins,
  faEdit,
  faEllipsisH,
  faFile,
  faFileArchive,
  faFileDownload,
  faFileImage,
  faFileInvoiceDollar,
  faFilePdf,
  faFileUpload,
  faFolderOpen,
  faGears,
  faKeyboard,
  faPaperclip,
  faPercent,
  faPlus,
  faPrint,
  faTimeline,
  faTimes,
  faTrash,
  faUsers,
  faWeightScale,
)

const store = createStore()

const i18n = createI18n({
  locale: store.getters.locale,
  fallbackLocale: 'en',
  messages: i18nMessages
})

Settings.defaultLocale = store.getters.locale

const router = createRouter()

const apolloClient = createApolloClient(router, store)

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
  watchLoading: (state) => {
    store.dispatch('setLoading', state)
  }
})

import FontAwesomeIcon from '@/components/FontAwesomeIcon'
import PageTitle from '@/components/PageTitle'

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('page-title', PageTitle)
  .use(router)
  .use(store)
  .use(apolloProvider)
  .use(i18n)
  .mount('#app')
