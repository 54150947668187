export const sv = {
    name: 'namn',
    att: 'att',
    city: 'ort',
    email: 'e-post',
    reference: 'referens',
    contact: 'kontakt',
    organizationNumber: 'organisationsnummer',
    phone: 'telefon',
    postalCode: 'postnummer',
    street: 'address',
    defaultPaymentTermDays: 'betalningsvillkor',
    invoiceCount: 'antal fakturor',
    address: 'adress',
}

export const en = {
    ...Object.fromEntries(Object.entries(sv).map(item => [item[0], item[0].replace(/[A-Z]+/g, str => ` ${str.toLowerCase()}`)])),
    isApprovedForFTax: 'is approved for F-tax',
}
