<template>
  <span class="icon fas"><font-awesome-icon :icon="icon" /></span>
</template>

<script>

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  props: ['icon'],
  components: { FontAwesomeIcon }
}
</script>
