export const sv = {
    name: 'namn',
    att: 'att',
    street: 'adress',
    postalCode: 'postnummer',
    city: 'ort',
}

export const en = {
    ...Object.fromEntries(Object.entries(sv).map(item => [item[0], item[0].replace(/[A-Z]+/g, str => ` ${str.toLowerCase()}`)])),
}
