// initial state
const state = {
  auth: localStorage.getItem('auth'),
  user: JSON.parse(localStorage.getItem('user')),
  companies: JSON.parse(localStorage.getItem('companies')) || [],
  selectedCompanyId: localStorage.getItem('selectedCompanyId'),
  settings: JSON.parse(localStorage.getItem('settings')) ?? {}
}

const getCompany = (state) => {
  for (var i = 0; i < state.companies.length; i++) {
    var company = state.companies[i]
    if (company.id === state.selectedCompanyId) {
      return company
    }
  }
}

// getters
const getters = {
  user: state => state.user,
  loggedIn: state => !!state.auth,
  ledgers: state =>  state.companies || [],
  companies: state => state.companies || [],
  ledgerId: state => state.selectedCompanyId,
  companyId: state => state.selectedCompanyId,
  ledger: state => getCompany(state),
  company: state => getCompany(state),
  functions: state => {
    var company = getCompany(state)
    if (!company) return []
    return company.functions.reduce((obj, item) => {
      obj[item.name] = true
      return obj
    }, {})
  },
  settings: state => state.settings,
}

// actions
const actions = {
  login ({ commit }, user) {
    commit('login', { user })
    commit('setUser', { user })
    if (user.companies?.length) {
      commit('setCompanies', { companies: user.companies })
      commit('selectCompany', { companyId: user.companies[0].id })
    }
  },
  setUser ({ commit }, user) {
    commit('setUser', { user })
  },
  logout ({ commit }) {
    commit('logout')
  },
  setCompanies ({ commit }, companies) {
    commit('setCompanies', { companies })
  },
  selectCompany ({ commit }, company) {
    let companyId
    if (typeof company === 'object') companyId = company.id
    if (typeof company === 'string') companyId = company
    commit('selectCompany', { companyId })
  },
  removeCompany ({ commit, state }, company) {
    commit('removeCompany', { company })
    if (company.id === state.selectedCompanyId) {
      commit('selectCompany', { company: state.companies.find(l => l.id !== company.id) })
    }
  },
  setSetting ({ commit }, { key, value }) {
    commit('setSetting', { key, value })
  },
}

// mutations
const mutations = {
  login (state, { user }) {
    localStorage.setItem('auth', user.auth)
  },
  setUser (state, { user }) {
    if (!user) {
      state.user = undefined
      localStorage.removeItem('user')
      return
    }
    localStorage.setItem('user', JSON.stringify({
      id: user.id,
      exportId: user.exportId,
      email: user.email,
      role: user.role,
    }))
    state.user = user
  },
  logout (state) {
    state.user = undefined
    state.companies = []
    state.selectedCompanyId = undefined
    localStorage.clear()
  }, 
  setCompanies (state, { companies }) {
    if (!companies) {
      state.companies = []
      localStorage.removeItem('companies')
      return
    }
    state.companies = companies
      .slice()
      .sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    localStorage.setItem('companies', JSON.stringify(state.companies.map(item => {
      return {
        id: item.id,
        name: item.name,
        functions: item.functions.map(item => {
          return {
            name: item.name
          }
        })
      }
    })));
  },
  selectCompany (state, { companyId }) {
    if (!companyId) {
      localStorage.removeItem('selectedCompanyId')
      localStorage.removeItem('functions')
      state.selectedCompanyId = undefined
    } else {
      state.selectedCompanyId = companyId
      localStorage.setItem('selectedCompanyId', companyId)
    }
  },
  removeCompany (state, { id }) {
    var index = state.companies.findIndex(l => l.id === id)
    if (index !== -1) state.companies.splice(index, 1)
  },
  setSetting (state, { key, value }) {
    if (value !== undefined) {
      state.settings[key] = value
    } else {
      delete state.settings[key]
    }
    localStorage.setItem('settings', JSON.stringify(state.settings))
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
