// initial state
const state = {
  worklogCustomerSelect: localStorage.getItem('customer-worklog-customer-select') || '',
  customerMonth: localStorage.getItem('customer-month-datetime') || ''
}

// getters
const getters = {
  worklogCustomerSelect: state => state.worklogCustomerSelect,
  customerMonth: state => state.customerMonth
}

// actions
const actions = {
  filterWorklogsByCustomer ({ commit }, name) {
    commit('filterWorklogsByCustomer', { name })
  },
  setCustomerMonth ({ commit }, date) {
    commit('setCustomerMonth', { date })
  }
}

// mutations
const mutations = {
  filterWorklogsByCustomer (state, { name }) {
    state.worklogCustomerSelect = name
    localStorage.setItem('customer-worklog-customer-select', name)
  },
  setCustomerMonth (state, { date }) {
    state.customerMonth = date
    localStorage.setItem('customer-month-datetime', date)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
