export const sv = {
    date: 'datum',
    article: 'artikel',
    project: 'projekt',
    customer: 'kund',
    quantity: 'antal',
    comment: 'kommentar',
}

export const en = {
    ...Object.fromEntries(Object.entries(sv).map(item => [item[0], item[0].replace(/[A-Z]+/g, str => ` ${str.toLowerCase()}`)])),
}
