<template>
  <app-nav-bar class="no-print" />
  <print-header class="do-print">
  </print-header>
  <div class="is-hidden-desktop no-print">
    <app-nav-menu version="touch" v-if="navIsActive" />
  </div>
  <div class="columns">
    <div class="column is-3-desktop is-2-fullhd is-hidden-touch no-print" v-if="$store.getters.loggedIn">
      <app-nav-menu version="desktop" />
    </div>
    <div class="column pb-0 pl-0"
      :class="{
        'is-12': $store.getters.loggedIn,
        'is-9-desktop': $store.getters.loggedIn,
        'is-10-fullhd': $store.getters.loggedIn,
      }"
    >
      <div class="content-view">
        <alert-messages />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import AppNavBar from '@/components/AppNavBar'
import AppNavMenu from '@/components/AppNavMenu'
import AlertMessages from '@/components/AlertMessages'
import PrintHeader from '@/components/PrintHeader'
import { companyListQuery } from '@/graphql/queries/company'
import { meQuery } from '@/graphql/queries/user'

export default {
  name: 'app',
  components: { AppNavBar, AppNavMenu, AlertMessages, PrintHeader },
  computed:{
    navIsActive: {
      get() { return this.$store.getters.navIsActive },
      set(value) { this.$store.dispatch('setNavActive', value) },
    },
  },
  apollo: {
    user: {
      query: meQuery,
      skip() { return !this.$store.getters.loggedIn },
      result ({ data }) {
        this.$store.dispatch('setUser', data?.user)
      }
    },
    companies: {
      query: companyListQuery,
      skip() { return !this.$store.getters.loggedIn },
      result ({ data }) {
        this.$store.dispatch('setCompanies', data?.companies)
      }
    },
  }
}

</script>

<style>

@media print {
  .no-print {
    display: none !important;
  }
  .content-view {
    overflow-y: hidden !important;
    height: auto !important;
  }
  .content-view .section:first-child {
    padding-top: 0;
  }
}

@media screen {
  .do-print {
    display: none !important;
  }
}

.content-view {
  overflow-y: scroll;
  height: calc(100vh - 3.25rem);
}

body::-webkit-scrollbar {
  display: none;
}

#app {
  font-family: Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-item,
.label,
.checkbox,
.title,
.subtitle,
.message-header,
.panel-heading,
.card-header-title,
.modal-card-title,
.capitalized,
button,
a:not(.nocap),
th,
label.radio {
  text-transform: capitalize;
}

.nowrap {
  white-space: nowrap;
}

.table-responsive {
  overflow-x: auto;
}

.checkbox {
  user-select: none
}

.pre {
  white-space: pre-wrap;
}

.table-responsive.has-menu-3 {
  padding-bottom: 7em;
}

.table-responsive.has-menu-2 {
  padding-bottom: 4em;
}

</style>
