var messages = {
  'add invoice row' : 'lägg till fakturarad',
  'add text row' : 'lägg till textrad',
  'empty': 'tom',
  'attachement': 'bilaga',
  'attachements': 'bilagor',
  'inbox': 'inkorg',
  'subject': 'ämne',
  'message': 'meddelande',
  'account class': 'kontoklass',
  'account classes': 'kontoklasser',
  'account group': 'kontogrupp',
  'account groups': 'kontogrupper',
  'account head': 'huvudkonto',
  'account heads': 'huvudkonton',
  'account plan': 'kontoplan',
  'account': 'konto',
  'accounting date': 'bokföringsdatum',
  'account posting': 'kontering',
  'accounting': 'bokföring',
  'accounts': 'konton',
  'active only': 'endast aktiva',
  'add user': 'lägg till användare',
  'add': 'lägg till',
  'address': 'adress',
  'admin': 'admin',
  'alla': 'alla',
  'allt': 'allt',
  'amount': 'summa',
  'approvedFTax': 'Godkänd för F-skatt',
  'article': 'artikel',
  'articles': 'artiklar',
  'assets': 'tillgångar',
  'att': 'att',
  'available': 'tillgängligt',
  'balance statement': 'balansräkning',
  'balance': 'balans',
  'bank account': 'bankkonto',
  'bank name': 'banknamn',
  'bankgiro': 'bankgiro',
  'benefit': 'förmån | förmåner',
  'book' : 'bokför',
  'budget': 'budget',
  'budgets': 'budgetar',
  'calendar': 'kalender',
  'cancel': 'avbryt',
  'change password': 'byt lösenord',
  'city': 'ort',
  'class': 'klass',
  'classes': 'klasser',
  'close': 'stäng',
  'comment': 'kommentar',
  'companies': 'bolag',
  'company name': 'bolagsnamn',
  'company': 'bolag',
  'compensation': 'ersättning',
  'compensations': 'ersättningar',
  'contact': 'kontakt',
  'create': 'skapa',
  'credit': 'credit',
  'current': 'nuvarande',
  'customer': 'kund',
  'customers': 'kunder',
  'dashboard': 'översikt',
  'date payment': 'betaldatum',
  'date': 'datum',
  'days': 'dagar',
  'debit': 'debet',
  'debts': 'skulder',
  'delete file': 'radera fil',
  'delete': 'ta bort',
  'description': 'beskrivning',
  'destination': 'destination',
  'disable': 'inaktivera',
  'disabled': 'inaktiverad',
  'download': 'hämta',
  'draft' : 'utkast',
  'driver log': 'körlog',
  'driver logs': 'körloggar',
  'driver statement': 'körjournal | körjournaler',
  'due date': 'förfallodatum',
  'edit': 'redigera',
  'email updated': 'e-post uppdaterad',
  'email': 'e-post',
  'employee': 'anställd',
  'employees': 'anställda',
  'employment number': 'Anställningsnummer',
  'enable': 'aktivera',
  'enabled': 'aktiv',
  'error': 'fel',
  'event': 'händelse',
  'events': 'händelser',
  'ex.vat': 'exkl. moms',
  'expenses': 'utgifter',
  'export': 'exportera',
  'export-singular': 'export',
  'exports': 'exporter',
  'fees': 'avgifter',
  'file': 'fil',
  'filename': 'filnamn',
  'files': 'filer',
  'fiscal year': 'räkenskapsår',
  'fiscal years': 'räkenskapsår',
  'from': 'från',
  'gross incl. fees': 'brutto inkl. avgifter',
  'gross': 'brutto',
  'group': 'grupp',
  'groups': 'grupper',
  'home': 'hem',
  'hour': 'timme',
  'hours': 'timmar',
  'inactive': 'inaktiv',
  'incl. vat': 'inkl. moms',
  'include': 'inkudera',
  'income': 'intäkt',
  'incomes': 'intäkter',
  'initial balance': 'ingående balans',
  'invoice date': 'fakturadatum',
  'invoice number': 'fakturanummer',
  'invoice number series': 'fakturanummerserier',
  'invoice recipient': 'fakturamottagare',
  'invoice recipients': 'fakturamottagare',
  'invoice rows': 'fakturarader',
  'invoice': 'faktura',
  'invoiced': 'fakturerat',
  'invoices': 'fakturor',
  'import': 'importera',
  'importing': 'importerar',
  'kilometers': 'kilometer',
  'languages': 'språk',
  'ledger': 'huvudbok',
  'ledgers': 'huvudböcker',
  'log': 'logg',
  'login': 'logga in',
  'logo': 'logga',
  'logout': 'logga ut',
  'main account': 'huvudkonto',
  'main accounts': 'huvudkonton',
  'module': 'modul',
  'modules': 'moduler',
  'month': 'månad',
  'multiple': 'flera',
  'name': 'namn',
  'net': 'netto',
  'new': 'ny',
  'next': 'nästa',
  'no': 'nej',
  'none': 'ingen',
  'notes': 'anteckningar',
  'number': 'nummer',
  'ny': 'ny',
  'nya': 'nya',
  'nytt': 'nytt',
  'orgno': 'orgnr',
  'organization number': 'organisationsnummer',
  'outgoing balance': 'utgående balans',
  'owner': 'ägare',
  'paid': 'betald',
  'password no match': 'lösenorden är olika',
  'password updated': 'lösenord ändrat',
  'password': 'lösenord',
  'pay slip': 'lönebesked',
  'pay slips': 'lönebesked',
  'payment terms': 'betalningsvillkor',
  'payment': 'betalning',
  'payments': 'betalningar',
  'penny rounding': 'öresavrundning',
  'period': 'period',
  'percent': 'procent',
  'phone': 'telefon',
  'planned': 'planerade',
  'post': 'post',
  'postal code': 'postnummer',
  'price': 'pris',
  'print': 'skriv ut',
  'project': 'projekt',
  'projects': 'projekt',
  'quantity': 'antal',
  'read': 'läsa',
  'receipt': 'kvitto',
  'receiver': 'mottagare',
  'recipient': 'mottagare',
  'recipients': 'mottagare',
  'reference': 'referens',
  'references': 'referenser',
  'register payments': 'registrera betalningar',
  'register': 'registrera',
  'reimbursement': 'ersättning | ersättningar',
  'repeat': 'repetera',
  'report': 'rapport',
  'reports': 'rapporter',
  'result statement': 'resultaträkning',
  'result': 'resultat',
  'route': 'rutt',
  'row': 'rad',
  'rows': 'rader',
  'salaries': 'löner',
  'salary notice': 'lönebesked',
  'salary notices': 'lönebesked',
  'salary': 'lön',
  'save': 'spara',
  'search': 'sök',
  'security functions': 'behörighetsfuntioner',
  'select': 'välj',
  'series': 'serie',
  'settings': 'inställningar',
  'show': 'visa',
  'sie': 'SIE',
  'social security number': 'personnummer',
  'sru': 'SRU',
  'street': 'adress',
  'sum': 'summa',
  'support': 'support',
  'switch': 'byt',
  'sysadmin': 'sysadmin',
  'tax table': 'skattetabell',
  'tax': 'skatt',
  'taxes': 'skatter',
  'template': 'mall',
  'text': 'text',
  'time period': 'tidsperiod',
  'time': 'tid',
  'type': 'typ',
  'to': 'till',
  'to pay': 'att betala',
  'total': 'totalt',
  'tracking': 'uppföljning',
  'transaction date': 'transaktionsdatum',
  'transaction details': 'transaktionsdetailjer',
  'transaction rows': 'transaktionsrader',
  'transaction': 'transaktion',
  'transactions': 'transaktioner',
  'uninvoiced': 'ofakturerat',
  'unit price': 'a-pris',
  'unit': 'enhet',
  'upcoming': 'kommande',
  'update': 'uppdatera',
  'used': 'använt',
  'user': 'användare',
  'users': 'användare',
  'valid': 'giltig',
  'value': 'värde',
  'vat number': 'VAT-nummer',
  'vat': 'moms',
  'vat rate': 'momssats',
  'vat rates': 'momssatser',
  'ver.number': 'ver.nummer',
  'ver.series': 'ver.serier',
  'verification': 'verifikat',
  'verifications': 'verifikat',
  'verification number': 'verifikationsnummer',
  'verification sequence': 'verifikatserie',
  'verification sequences': 'verifikatserier',
  'view': 'visa',
  'worklog': 'arbetslog',
  'write': 'skriva',
  'year': 'år',
  'yes': 'ja',
  bool: {
    true: 'ja',
    false: 'nej'
  },
  component: {
    dashboard: {
      fileTotal: 'utrymme',
      fileFree: 'ledigt utrymme',
      fileCount: 'antal filer',
      fileAvg: 'genomsnittlig storlek'
    }
  },
  accountTypeEnum: {
    ASSET: 'Tillgång',
    DEBT: 'Skuld',
    INCOME: 'Intäkt',
    EXPENCE: 'Utgift',
    INTERNAL: 'INTERNAL',
    NON_FINANCIAL: 'NON_FINANCIAL',
  },
  accountReportEnum: {
    RESULT: 'Resultat',
    BALANCE: 'Balans',
  },
  _error: {
    'empty list' : 'Inga objekt',
    'file_to_large': 'Filen är för stor',
    'multiple vat rates': 'Stödjer inte flera olika momssatser'
  },
  _module: {
    journal: 'bokföring',
    customer: 'kunder',
    invoice: 'fakturering',
    salary: 'lön',
  },
  _security_function: {
    ledger_read: 'huvudbok: läs',
    ledger_admin: 'huvudbok: administratör',
    module_journal: 'modul: bokföring',
    module_customer: 'modul: kunder',
    module_invoice: 'modul: fakturering',
    module_salary: 'Modul: lön',
    journal_read: 'bokföring: läs',
    journal_write: 'bokföring: skriv',
    journal_admin: 'bokföring: administratör',
    customer_read: 'kunder: läs',
    customer_admin: 'kunder: admin',
    customer_write: 'kunder: skriv',
    work_read: 'order: läs',
    work_write: 'order: skriv',
    invoice_read: 'fakturor: läs',
    invoice_write: 'fakturor: skriv',
    invoice_admin: 'fakturor: admin',
    salary_admin: 'lön: admin'
  },
  enums: {
    driveLogDirection: {
      ONE_WAY: 'enkelresa',
      ROUND_TRIP: 'tur och retur',
      RETURN_TRIP: 'returresa'
    }
  },
  language: {
    phrase: 'språk',
    sv: 'svenska',
    en: 'engelska'
  }
}
export default messages
