import gql from 'graphql-tag'

export const userQuery = gql`query ($id: ID!) {
  user (id: $id) { id name: email } }`

export const companyUserListQuery = gql`query companyUser {
  company {
    id
    maxEmploymentNumber
    users {
      id
      name: email
      email
      employee { id }
    }
  } }`

export const meQuery = gql`query { user: me {
  id auth email role } }`

export const userMutation = gql`mutation ($email: String!) {
  user: updateUser (email: $email) { auth id exportId email } }`

export const passwordMutation = gql`mutation ($currentPassword: String! $newPassword: String!) {
  success: updatePassword (currentPassword: $currentPassword newPassword: $newPassword) }`

export const authenticateMutation = gql`mutation ($email: String! $password: String!) {
  user: authenticate (username: $email password: $password) {
    id auth email role
    companies {
      id name openJournalCount 
      functions {
        name
      }
    }
  }}`

export const newUserMutation = gql`mutation ($email: String! $password: String!) {
  user: registerUser (username: $email password: $password) {
    id auth email } }`
