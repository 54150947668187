<template>
  <div class="level">
    <div class="level-left">
      <div class="level-item">{{ company?.name}}</div>
    </div>
    <div class="level-right">
      <div class="level-item has-text-right">{{ now }}</div>
    </div>
  </div>
</template>

<script>

import { DateTime } from "luxon";

export default {
  data () {
    return {
      now: DateTime.now().toFormat('yyyy-MM-dd HH:mm')
    }
  },
  computed: {
    company() {
      return this.$store.getters.company
    },
  },
}
</script>

<style>

</style>
