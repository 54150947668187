export const sv = {
    employmentNumber: 'anställningsnummer',
    name: 'namn',
    socialSecurityNumber: 'personnummer',
    email: 'e-post',
    bankName: 'bank',
    bankAccountNumber: 'kontonummer',
    taxTable: 'skattetabell',
    userId: 'användare',
    dateEmployed: 'anställd sedan',
    taxRate: 'procentuell skatt',
    salaryAmount: 'avtalad lön'
}

export const en = {
    ...Object.fromEntries(Object.entries(sv).map(item => [item[0], item[0].replace(/[A-Z]+/g, str => ` ${str.toLowerCase()}`)])),
}
