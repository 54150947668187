<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content p-6">
      <div class="box p-0">
        <nav class="panel">
          <p class="panel-heading">
            {{ $t('companies') }}
          </p>
          <router-link class="panel-block" v-for="company in $store.getters.companies" :key="company.id"
            :to="{ name: 'company-root', params: { companyId: company.id }}" @click="$emit('close')">
            <font-awesome-icon class="panel-icon" icon="folder-open" />
            {{ company.name }}
          </router-link>
        </nav>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="$emit('close')"></button>
  </div>
</template>

<script>
export default {
  props: ['active'],
  methods: {
  },
}
</script>

<style scoped>

</style>
