<template>
  <div class="menu-container" @click="isActive = false">
    <aside class="menu has-text-right-touch p-4" :class="menuclass" @click.stop="">
      <template v-if="$store.getters.loggedIn && !$store.getters.companyId">
        <p class="menu-label">
          {{ $t('companies') }}
        </p>
        <ul class="menu-list has-text-right-touch">
          <li v-for="company in companies" :key="company.id">
            <router-link class="navbar-item" :to="{ name: 'company-root', params: { companyId: company.id }}">
              {{ company.name }}
            </router-link>
          </li>
        </ul>
      </template>

      <template v-for="group in menu" :key="group.name">
        <p class="menu-label" @click="expandMenuGroupToggle(group)">
          <a class="has-text-grey-dark">{{ $t(group.name) }}
            <font-awesome-icon icon="chevron-down" v-if="!group.isExpanded"/>
            <font-awesome-icon icon="chevron-up"   v-if="group.isExpanded"/>
          </a>
        </p>
        <ul class="menu-list has-text-right-touch" v-if="group.isExpanded">
          <li v-for="route in group.routes" :key="route.name" @click="isActive = false">
            <router-link class="navbar-item" :to="{ name: route.name, params: route.params }">
              <template v-if="(typeof route.title == 'string')">{{ $t(route.title) }}</template>
              <template v-if="Array.isArray(route.title)">
                <span v-for="(title) in route.title" :key="title">{{ $t(title) }}
                  <span></span>
                </span>
              </template>
            </router-link>
          </li>
        </ul>
      </template>
      <template v-if="!$store.getters.loggedIn">
        <router-link class="navbar-item" :to="{ name: 'login' }">{{ $t('login') }}</router-link>
        <router-link class="navbar-item" :to="{ name: 'register' }">{{ $t('register') }}</router-link>
      </template>
    </aside>
  </div>
</template>

<script>

import { Settings } from 'luxon'

export default {
  props: [ 'version' ],
  data () {
    return {
    }
  },
  computed: {
    isActive: {
      get() { return this.$store.getters.navIsActive },
      set(value) { this.$store.dispatch('setNavActive', value) },
    },
    company() {
      return this.$store.getters.company
    },
    menuclass () {
      return 'menu-'+this.version;
    },
    menu() {
      // routes i router.js med meta.menuGroup definierat, sorterad på meta.menuSort
      const routes = this.$router.getRoutes()
      .filter(item => item.meta.menuGroup)
      .sort((a, b) => a.meta.menuSort - b.meta.menuSort)
      .map(item => {
        // tolkar förväntade parametrar från path-sträng
        const parameters = item.path.split('/').filter(item => item.startsWith(':')).map(item => item.substring(1));
          // tilldelar förväntade parametrar ifrån nuvarande route
        const params = Object.fromEntries(parameters.map(item => {
          return [ item, this.$route.params[item] || null ]
        }));

        // Tilldelar companyId ifrån session
        if (params.companyId === null) {
          params.companyId = this.company?.id;
        }

        const authGuard = item.beforeEnter ? item.beforeEnter({...item, params}, this.$route, (next) => next) : null;

        return {
          name: item.name,
          title: item.meta.title,
          menuGroup: item.meta.menuGroup,
          isActive: item.name == this.$route.name,
          params,
          authGuard,
        }
      })
      .filter(item => {
        // filtrerar länkar som saknar minst en parameter. router-link smäller annars.
        return Object.entries(item.params).findIndex(item => item[1] == null) == -1
          // filtrerar om navigering hindras av authGuard
          && item.authGuard == undefined;
      });

      const expandedMenuGroups = this.$store.getters.expandedMenuGroups;

      // grupperar på meta.menuGroup
      const groups = routes.reduce((grps, item) => {
        if (grps.findIndex(g => g.name == item.menuGroup) == -1) return [...grps, { name: item.menuGroup, sort: item.menuSort}]
        return grps;
      }, [])
      .map(item => {
        return { ...item,
          routes: routes.filter(r => r.menuGroup == item.name),
          isExpanded: expandedMenuGroups.indexOf(item.name) != -1,
        }
      });
      return groups;
    }
  },
  methods: {
    setLanguage (locale) {
      this.$store.dispatch('setLocale', locale)
      this.$i18n.locale = locale
      Settings.defaultLocale = locale
    },
    expandMenuGroupToggle(group) {
      this.$store.dispatch('expandMenuGroup', [ group.name, !group.isExpanded ])
    },
  },
}
</script>

<style>

.menu-container:has(.menu-touch) {
  background: rgba(0,0,0,.5);
  position: fixed;
  z-index: 1;
  width: 100%;
  height: calc(100vh - 3.25rem);
}

.menu-touch {
  background: white;
  position: fixed;
  z-index: 2;
  overflow-y: auto;
  height: calc(100vh - 3.25rem);
  right: 0;
  min-width: 20rem;
}

.menu-desktop {
  background: rgba(0,0,0,.025);
  position: fixed;
  overflow-y: auto;
  height: calc(100vh - 3.25rem);
}

.menu::-webkit-scrollbar {
  display: none;
}

</style>
