<template>
  <h1 class="title">{{ title }}
    <slot></slot>
  </h1>
  <p class="subtitle" v-if="subtitle">{{ subtitle }}</p>

</template>

<script>

export default {
  props: [ 'customTitle', 'subtitle' ],
  computed: {
    title () {
      if (this.customTitle) return this.customTitle;
      let title = this.$route.meta.title;
      if (typeof title === 'string') return this.$t(title);
      let t = []
      for (let i in title) {
        t.push(this.$t(title[i]));
      }
      return t.join(' ');
    }
  }
}

</script>