import { createStore } from 'vuex'

import { DateTime } from 'luxon';

import customer from './modules/customer'
import event from './modules/event'
import journal from './modules/journal'
import reports from './modules/reports'
import user from './modules/user'
import nav from './modules/nav'

// state
const state = {
  loading: false,
  gqlQueryCount: 0,
  selectedMonth: DateTime.now().toISODate(),
  locale: localStorage.getItem('locale') || 'sv',
  errors: []
}

// getters
const getters = {
  selectedMonth: state => state.selectedMonth,
  isLoading: state => state.loading,
  locale: state => state.locale,
  alerts: state => state.errors.map(item => {
    return {
      id: item.id,
      message: item.message,
      type: item.type
    }
  })
}

// actions
const actions = {
  selectMonth: ({ commit }, month) => {
    commit('selectMonth', { month })
  },
  setLoading: ({ commit }, loading) => {
    commit('setLoading', { loading })
  },
  setLocale: ({ commit }, locale) => {
    commit('setLocale', { locale })
  },
  registerError: ({ commit }, error) => {
    commit('registerError', { error })
  },
  clearError: ({ commit }, id) => {
    commit('clearError', { id })
  },
}

// mutations
const mutations = {
  selectMonth (state, { month }) {
    state.selectedMonth = month
  },
  setLoading (state, { loading }) {
    state.loading = state.gqlQueryCount || loading
  },
  setLocale (state, { locale }) {
    state.locale = locale
    localStorage.setItem('locale', locale)
  },
  registerError (state, { error }) {
    if (error.graphQLErrors) {
      state.errors = state.errors.concat(error.graphQLErrors.map((item) => { return { message: item.message } }))
    } else if (error.error) {
      state.errors.push({ message: error.error })
    } else if (error.result?.message) {
      state.errors.push({ message: error.result.message, type: error.type })
    } else if (error.message) {
      state.errors.push({ message: error.message, type: error.type })
    } else if (typeof error == 'string') {
      state.errors.push({ message: error })
    } else {
      state.errors.push({ message: 'unknown error' })
    }
    for (var i = 0; i < state.errors.length; i++) {
      if (!state.errors[i].id) {
        state.errors[i].id = Math.floor(Math.random() * Math.pow(10, 9))
      }
      if (!state.errors[i].type) {
        state.errors[i].type = 'danger'
      }
    }
  },
  clearError (state, { id }) {
    if (!id) {
      state.errors.length = 0
      return
    }
    for (var i = 0; i < state.errors.length; i++) {
      if (state.errors[i].id === id) {
        state.errors.splice(i--, 1)
      }
    }
  }
}

export default function() {
  return createStore({
    state,
    getters,
    actions,
    mutations,
    modules: {
      customer,
      event,
      journal,
      reports,
      user,
      nav,
    },
    strict: process.env.NODE_ENV !== 'production'
  })
}
