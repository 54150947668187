
import sv from './sv'
import en from './en'
import * as Address from './entities/address'
import * as Article from './entities/article'
import * as Company from './entities/company'
import * as Customer from './entities/customer'
import * as Worklog from './entities/worklog'
import * as WorkProject from './entities/workproject'
import * as Invoice from './entities/invoice'
import * as Employee from './entities/employee'
import * as InvoiceReference from './entities/invoice-reference'
import * as InvoiceReferenceType from './entities/invoice-reference-type'
import * as SalaryStatement from './entities/salary-statement'

const entities = {
  Address,
  Article,
  Company,
  Customer,
  Employee,
  Worklog,
  WorkProject,
  Invoice,
  InvoiceReference,
  InvoiceReferenceType,
  SalaryStatement,
}

export default {
  sv: {
    ...sv,
    entities: Object.fromEntries(Object.entries(entities).map(item => {
      return [
        item[0],
        Object.entries(item[1]).find(item => item[0] == 'sv')[1]
      ];
    })),
  },
  en: {
    ...en,
    entities: Object.fromEntries(Object.entries(entities).map(item => {
      return [
        item[0],
        Object.entries(item[1]).find(item => item[0] == 'en')[1]
      ];
    })),
  },
}
