<template>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <!--a class="navbar-item" href="https://bulma.io">
        <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28">
      </a-->

      <a class="navbar-item">
        <img src="/img/icons/gs-192x192.png">
      </a>

      <a class="navbar-item is-hidden-desktop" @click="companyModalActive = true" v-if="company">
          {{ company?.name }}
      </a>
      
      <a @click="isActive = !isActive" role="button" class="navbar-burger" :class="{ 'is-active': isActive }" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">

      <div class="navbar-start" v-if="loggedIn">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"  v-if="!company">
            {{ $t('company') }}
          </a>
          <router-link class="navbar-link" :to="{ name: 'company-root', params: { companyId: company.id }}" v-if="company">
            {{ company?.name }}
          </router-link>

          <div class="navbar-dropdown">
            <router-link v-for="company in $store.getters.companies" :key="company.id"
              class="navbar-item" :to="{ name: 'company-root', params: { companyId: company.id }}">
              {{ company.name }}
            </router-link>
          </div>
        </div>
      </div>

      <div class="navbar-end" v-if="!$store.getters.loggedIn">
        <router-link class="navbar-item" :to="{ name: 'login' }">{{ $t('login') }}</router-link>
        <router-link class="navbar-item" :to="{ name: 'register' }">{{ $t('register') }}</router-link>
      </div>
    </div>
    <company-select-modal :active="companyModalActive" @close="companyModalActive = false" />
  </nav>
</template>

<script>

import CompanySelectModal from "@/components/CompanySelectModal.vue";

export default {
  components: { CompanySelectModal },
  data () {
    return {
      companyModalActive: false
    }
  },
  computed: {
    loggedIn () { return this.$store.getters.loggedIn },
    isActive: {
      get() { return this.$store.getters.navIsActive },
      set(value) { this.$store.dispatch('setNavActive', value) },
    },
    company() {
      return this.$store.getters.company
    },
    companies() {
      return this.$store.getters.companies
    },
  },
  methods: {
  },
}
</script>

<style>

</style>
