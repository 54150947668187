export const sv = {
    dateFrom: 'period från',
    dateTo: 'period till',
    datePayment: 'betaldatum',
    amountGross: 'bruttolön',
    amountTax: 'skatt',
    amountFees: 'avgifter',
    amountOther: 'ersättningar',
    taxTable: 'skattetabell',
    taxRate: 'procentuell skatt',
}

export const en = {
    ...Object.fromEntries(Object.entries(sv).map(item => [item[0], item[0].replace(/[A-Z]+/g, str => ` ${str.toLowerCase()}`)])),
}
