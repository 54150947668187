import { DateTime } from "luxon"
import * as sortBy from 'sort-by'

// initial state
const state = {
  journals: [],
  searchedDateFrom: localStorage.getItem('searchedDateFrom') ? localStorage.getItem('searchedDateFrom') : DateTime.now().minus({ years: 1 }).startOf('year').toISODate(),
  searchedLimit: JSON.parse(localStorage.getItem('searchedLimit')),
  sortPredicates: ['-datetime', '-verificationNumber', undefined]
}

const getSortPredicate = (state, i) => {
  if (state.sortPredicates.length < i + 1) return undefined
  return state.sortPredicates[i]
}

// getters
const getters = {
  journalList: state => state.journals,
  sortedJournalList: state => {
    return state.journals.slice().sort(sortBy(
      getSortPredicate(state, 0),
      getSortPredicate(state, 1),
      getSortPredicate(state, 2)
    ))
  },
  searchedDateFrom: state => state.searchedDateFrom,
  searchedLimit: state => state.searchedLimit ? state.searchedLimit : 10
}

// actions
const actions = {
  setJournalList ({ commit }, journals) {
    commit('setJournalList', { journals })
  },
  searchJournalByDateFrom ({ commit }, date) {
    commit('searchJournalByDateFrom', { date })
  },
  searchJournalByLimit ({ commit }, limit) {
    commit('searchJournalByLimit', { limit })
  },
  sortJournalsBy ({ commit }, predicate) {
    commit('sortJournalTableBy', { predicate })
  }
}

// mutations
const mutations = {
  setJournalList (state, { journals }) {
    state.journals = journals.map(item => {
      let journal = {}
      for (let key in item) journal[key] = item[key]

      if (!journal.verificationNumber) return journal

      var year = parseInt(journal.verificationNumber.substr(0, 4))
      var number = parseInt(journal.verificationNumber.substr(5))
      journal.verificationNumberSortOrder = year * 10000 + number
      return journal
    })
  },
  searchJournalByDateFrom (state, { date }) {
    state.searchedDateFrom = date
    if (date) localStorage.setItem('searchedDateFrom', state.searchedDateFrom)
    else localStorage.removeItem('searchedDateFrom')
  },
  searchJournalByLimit (state, { limit }) {
    state.searchedLimit = limit
    if (limit) localStorage.setItem('searchedLimit', JSON.stringify(state.searchedLimit))
    else localStorage.removeItem('searchedLimit')
  },
  sortJournalTableBy (state, { predicate }) {
    if (state.sortPredicates[0].substr(state.sortPredicates[0].length - predicate.length) === predicate) {
      if (state.sortPredicates[0].substr(0, 1) !== '-') predicate = '-' + predicate
      state.sortPredicates.splice(0, 1, predicate)
      return
    }
    state.sortPredicates.splice(0, 0, predicate)
    state.sortPredicates.pop()
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
