import gql from 'graphql-tag'

const companyFragment = `
  id name openJournalCount logoBase64
  emailInbox {
    id subject
    attachements {
      id filename
    }
  }
  fiscalYears {
    id name dateFrom, dateTo sieFileUrl
    verificationSequences { id code name }
  }
  functions { name }
  users { id name: email email }
  bankgiro
  email
  invoiceReference
  isApprovedForFTax
  organizationNumber
  phone
  address {
    street
    postalCode
    city
  }
  vatNumber
  nextInvoiceNumber
  invoiceCount`

export const companyQuery = gql`query { company {
  ${companyFragment}
} }`

export const companyListQuery = gql`query { companies {
  id name openJournalCount logoBase64 invoiceReference
  organizationNumber
  functions { name }
  users { id name: email }
  emailInbox { id }
} }`

export const companyCreateMutation = gql`mutation ($company: CompanyCreateInput!) {
  company: createCompany (company: $company) {
    ${companyFragment}
  } }`

export const companyUpdateMutation = gql`mutation ($company: CompanyUpdateInput!) {
  company: updateCompany (company: $company) {
    ${companyFragment}
  } }`

export const companyDeleteMutation = gql`mutation DeleteCompany($id: ID!) {
  deleted: deleteCompany(id: $id, deleteShares: false)
}`

export const closeTransactionsMutation = gql`mutation ($id: ID!) {
  company: closeJournals(companyId: $id) {
    company {id openJournalCount }
    journals { id accountingDate verificationNumber } } }`
