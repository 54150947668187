import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, concat } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { DateTime } from 'luxon';

export function createApolloClient (router, store) {

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          articles: { merge (_existing, incoming) { return [...incoming]; } },
          customers: { merge (_existing, incoming) { return [...incoming]; } },
          invoiceNumberSeriesList: { merge (_existing, incoming) { return [...incoming]; } },
          projects: { merge (_existing, incoming) { return [...incoming]; } },
          budgets: { merge (_existing, incoming) { return [...incoming]; } },
          invoiceNumberSequences: { merge (_existing, incoming) { return [...incoming]; } },
        }
      },
      Company: {
        fields: {
          fiscalYears: { merge (existing, incoming) { return [...incoming]; } },
          customers: { merge (_existing, incoming) { return [...incoming]; } },
          invoiceNumberSequences: { merge (_existing, incoming) { return [...incoming]; } },
        }
      },
      Customer: {
        fields: {
          articles: { merge (existing, incoming) { return [...incoming]; } },
          projects: { merge (existing, incoming) { return [...incoming]; } },
        }
      },
      Employee: {
        fields: {
          driveLogs: { merge (existing, incoming) { return [...incoming]; } }
        }
      }
    }
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    let headers = {
      auth: localStorage.getItem('auth'),
      localtime: DateTime.now().toISO()
    };
    if (router.currentRoute.value.params.companyId) {
      headers['company-id'] = router.currentRoute.value.params.companyId;
    }
    operation.setContext({ headers })
    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      store.dispatch('registerError', { graphQLErrors })
    }

    if (networkError) {
      store.dispatch('registerError', networkError)
    }
  })

  const link = createHttpLink({ uri: process.env.VUE_APP_API_URL + 'graphql' })

  const apolloClient = new ApolloClient({
    link: concat(concat(authMiddleware, errorLink), link),
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production'
  })

  return apolloClient
}
