<template>
  <div class="container" v-if="alerts.length">
    <div class="message" :class="alert.class" v-for="alert in alerts" :key="alert.key">
      <div class="message-body">
        {{ alert.message }}
        <button class="delete" :class="alert.class" @click.prevent="closeAlert(alert)" style="float: right;" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    alerts () {
      return this.$store.getters.alerts.map(item => {
        return {
          key: item.id,
          message: item.message,
          class: item.type === 'success' ? 'is-success' : 'is-danger'
        }
      })
    }
  },
  methods: {
    closeAlert (alert) {
      this.$store.dispatch('clearError', alert.key)
    }
  }
}
</script>

<style scoped>

</style>
