var messages = {
  'add invoice row' : 'add invoice row',
  'add text row' : 'add text row',
  'empty': 'empty',
  'attachement': 'attachement',
  'attachements': 'attachements',
  'inbox': 'inbox',
  'subject': 'subject',
  'message': 'message',
  'account class': 'account class',
  'account classes': 'account classes',
  'account group': 'account group',
  'account groups': 'account groups',
  'account head': 'account head',
  'account heads': 'account heads',
  'account plan': 'account plan',
  'account': 'account',
  'accounting date': 'accounting date',
  'account posting': 'accounting',
  'accounting': 'accounting',
  'accounts': 'accounts',
  'active only': 'active only',
  'add user': 'add user',
  'add': 'add',
  'address': 'street',
  'admin': 'admin',
  'alla': 'all',
  'allt': 'all',
  'amount': 'amount',
  'approvedFTax': 'Approved for F-tax',
  'article': 'article',
  'articles': 'articles',
  'assets': 'assets',
  'att': 'att',
  'available': 'available',
  'balance statement': 'balance statement',
  'balance': 'balance',
  'bank account': 'bank account',
  'bank name': 'bank name',
  'bankgiro': 'bankgiro',
  'benefit': 'benefit | benefits',
  'book' : 'book',
  'budget': 'budget',
  'budgets': 'budgets',
  'calendar': 'calendar',
  'cancel': 'cancel',
  'change password': 'change password',
  'city': 'city',
  'class': 'class',
  'classes': 'classes',
  'close': 'close',
  'comment': 'comment',
  'companies': 'companies',
  'company name': 'company name',
  'company': 'company',
  'compensation': 'compensation',
  'compensations': 'compensations',
  'contact': 'contact',
  'create': 'create',
  'credit': 'credit',
  'current': 'current',
  'customer': 'customer',
  'customers': 'customers',
  'dashboard': 'dashboard',
  'date payment': 'date payment',
  'date': 'date',
  'days': 'days',
  'debit': 'debit',
  'debts': 'debts',
  'delete file': 'delete file',
  'delete': 'delete',
  'description': 'description',
  'destination': 'destination',
  'disable': 'disable',
  'disabled': 'disabled',
  'download': 'download',
  'draft' : 'draft',
  'driver log': 'driver log',
  'driver logs': 'driver logs',
  'driver statement': 'driver statement | driver statements',
  'due date': 'due date',
  'edit': 'edit',
  'email updated': 'e-mail updated',
  'email': 'e-mail',
  'employee': 'employee',
  'employees': 'employees',
  'employment number': 'employment number',
  'enable': 'enable',
  'enabled': 'enabled',
  'error': 'error',
  'event': 'event',
  'events': 'events',
  'ex.vat': 'excl. VAT',
  'expenses': 'expenses',
  'export': 'export',
  'export-singular': 'export',
  'exports': 'exports',
  'fees': 'fees',
  'file': 'file',
  'filename': 'filename',
  'files': 'files',
  'fiscal year': 'fiscal year',
  'fiscal years': 'fiscal years',
  'from': 'from',
  'gross incl. fees': 'gross incl. fees',
  'gross': 'gross',
  'group': 'group',
  'groups': 'groups',
  'home': 'home',
  'hour': 'hour',
  'hours': 'hours',
  'inactive': 'inactive',
  'incl. vat': 'incl. vat',
  'include': 'include',
  'income': 'income',
  'incomes': 'incomes',
  'initial balance': 'initial balance',
  'invoice date': 'invoice date',
  'invoice number': 'invoice number',
  'invoice number series': 'invoice number series',
  'invoice recipient': 'invoice recipient',
  'invoice recipients': 'invoice recipients',
  'invoice rows': 'invoice rows',
  'invoice': 'invoice',
  'invoiced': 'invoiced',
  'invoices': 'invoices',
  'import': 'import',
  'importing': 'importing',
  'kilometers': 'kilometers',
  'languages': 'languages',
  'ledger': 'ledger',
  'ledgers': 'ledgers',
  'log': 'log',
  'login': 'login',
  'logo': 'logo',
  'logout': 'logout',
  'main account': 'main account',
  'main accounts': 'main accounts',
  'module': 'module',
  'modules': 'modules',
  'month': 'month',
  'multiple': 'multiple',
  'name': 'name',
  'net': 'net',
  'new': 'new',
  'next': 'next',
  'no': 'no',
  'none': 'none',
  'notes': 'notes',
  'number': 'number',
  'ny': 'new',
  'nya': 'new',
  'nytt': 'new',
  'orgno': 'orgno',
  'organization number': 'organization number',
  'outgoing balance': 'outgoing balance',
  'owner': 'owner',
  'paid': 'paid',
  'password no match': 'passwords doesn\'t match',
  'password updated': 'password updated',
  'password': 'password',
  'pay slip': 'pay slip',
  'pay slips': 'pay slips',
  'payment terms': 'payment terms',
  'payment': 'payment',
  'payments': 'payments',
  'penny rounding': 'rounding',
  'period': 'period',
  'percent': 'percent',
  'phone': 'phone',
  'planned': 'planned',
  'post': 'post',
  'postal code': 'postal code',
  'price': 'price',
  'print': 'print',
  'project': 'project',
  'projects': 'projects',
  'quantity': 'quantity',
  'read': 'read',
  'receipt': 'receipt',
  'receiver': 'receiver',
  'recipient': 'recipient',
  'recipients': 'recipients',
  'reference': 'reference',
  'references': 'references',
  'register payments': 'register payments',
  'register': 'register',
  'reimbursement': 'reimbursement | reimbursements',
  'repeat': 'repeat',
  'report': 'report',
  'reports': 'reports',
  'result statement': 'result statement',
  'result': 'result',
  'route': 'route',
  'row': 'row',
  'rows': 'rows',
  'salaries': 'salaries',
  'salary notice': 'salary notice',
  'salary notices': 'salary notices',
  'salary': 'salary',
  'save': 'save',
  'search': 'search',
  'security functions': 'security functions',
  'select': 'select',
  'series': 'serie',
  'settings': 'settings',
  'show': 'show',
  'sie': 'SIE',
  'social security number': 'social security number',
  'sru': 'SRU',
  'street': 'street',
  'sum': 'sum',
  'support': 'support',
  'switch': 'switch',
  'sysadmin': 'sysadmin',
  'tax table': 'tax table',
  'tax': 'tax',
  'taxes': 'taxes',
  'template': 'template',
  'text': 'text',
  'time period': 'time period',
  'time': 'time',
  'type': 'type',
  'to': 'to',
  'to pay': 'to pay',
  'total': 'total',
  'tracking': 'tracking',
  'transaction date': 'transaction date',
  'transaction details': 'transaction details',
  'transaction rows': 'transaction rows',
  'transaction': 'transaction',
  'transactions': 'transactions',
  'uninvoiced': 'uninvoiced',
  'unit price': 'unit price',
  'unit': 'unit',
  'upcoming': 'upcoming',
  'update': 'update',
  'used': 'used',
  'user': 'user',
  'users': 'users',
  'valid': 'valid',
  'value': 'value',
  'vat number': 'vat number',
  'vat': 'VAT',
  'vat rate': 'VAT rate',
  'vat rates': 'VAT rates',
  'ver.number': 'ver.number',
  'ver.series': 'ver.series',
  'verification': 'verification',
  'verifications': 'verifications',
  'verification number': 'verification number',
  'verification sequence': 'verification series',
  'verification sequences': 'verification series',
  'view': 'view',
  'worklog': 'worklog',
  'write': 'write',
  'year': 'year',
  'yes': 'yes',
  bool: {
    true: 'yes',
    false: 'no'
  },
  component: {
    dashboard: {
      fileTotal: 'total size',
      fileFree: 'free space',
      fileCount: 'file count',
      fileAvg: 'average size'
    }
  },
  accountTypeEnum: {
    ASSET: 'ASSET',
    DEBT: 'DEBT',
    INCOME: 'INCOME',
    EXPENCE: 'EXPENCE',
    INTERNAL: 'INTERNAL',
    NON_FINANCIAL: 'NON_FINANCIAL',
  },
  accountReportEnum: {
    RESULT: 'RESULT',
    BALANCE: 'BALANCE',
  },
  _module: {
    journal: 'accounting',
    customer: 'customers',
    invoice: 'invoicing',
    salary: 'salary',
  },
  _error: {
    'empty list' : 'No objects',
    file_to_large: 'The file is too large',
    'multiple vat rates': 'Multiple vat rates not supported'
  },
  _security_function: {
    ledger_read: 'Ledger: read',
    ledger_admin: 'Ledger: admin',
    module_journal: 'Module: journal',
    module_customer: 'Module: customer',
    module_invoice: 'Module: invoice',
    module_salary: 'Module: salary',
    journal_read: 'Journal: read',
    journal_write: 'Journal: write',
    journal_admin: 'Journal: admin',
    customer_read: 'Customer: read',
    customer_admin: 'Customer: admin',
    customer_write: 'Customer: write',
    work_read: 'Work: read',
    work_write: 'Work: write',
    invoice_read: 'Invoice: read',
    invoice_write: 'Invoice: write',
    invoice_admin: 'Invoice: admin',
    salary_admin: 'salary: admin'
  },
  enums: {
    driveLogDirection: {
      ONE_WAY: 'one way',
      ROUND_TRIP: 'round trip',
      RETURN_TRIP: 'return trip'
    }
  },
  language: {
    phrase: 'language',
    sv: 'swedish',
    en: 'english'
  }
}
export default messages
