// initial state
const state = {
  searchedName: localStorage.getItem('event-searched-name')
}

// getters
const getters = {
  eventSearchedName: state => state.searchedName
}

// actions
const actions = {
  searchEventByName ({ commit }, name) {
    commit('searchEventByName', { name })
  }
}

// mutations
const mutations = {
  searchEventByName (state, { name }) {
    state.searchedName = name
    localStorage.setItem('event-searched-name', name)
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
