// initial state
const state = {
  navIsActive: false,
  expandedMenuGroups: JSON.parse(localStorage.getItem('expandedMenuGroups')) || []
}

// getters
const getters = {
  navIsActive: state => state.navIsActive,
  expandedMenuGroups: state => state.expandedMenuGroups,
}

// actions
const actions = {
  setNavActive ({ commit }, value){
    commit('setNavActive', value);
  },
  expandMenuGroup ({ commit }, args){
    commit('expandMenuGroup', { group: args[0], expand:args[1] });
  },
}

// mutations
const mutations = {
  setNavActive (state, value) {
    state.navIsActive = value;
  },
  expandMenuGroup (state, { group, expand } ) {
    let groups = state.expandedMenuGroups;
    if (expand && groups.indexOf(group) == -1) {
      state.expandedMenuGroups = [ ...groups, group ];
    } else if (!expand) {
      state.expandedMenuGroups = groups.filter(item => item != group)
    }
    localStorage.setItem('expandedMenuGroups', JSON.stringify(state.expandedMenuGroups));
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
