import { createRouter, createWebHistory } from "vue-router";

var authGuard = (args) => {
  return (to, from, next) => {
    var user = JSON.parse(localStorage.getItem('user'));
    if (!user) user = {};

    var companyId = to.params.companyId;

    const companies = JSON.parse(localStorage.getItem('companies')) || [];
    const company = companies.find(item => item.id === companyId);

    if (!localStorage.getItem('auth')) {
      return next('/login');
    }

    if (!args) {
      return next();
    }

    if (args.role && args.role !== user.role) {
      return next('/forbidden');
    }

    if (args.functions?.length) {
      let allowed = false
      const functions = company?.functions || [];

      for (let i in args.functions) {
        let func = args.functions[i];
        if (functions.findIndex(item => item.name === func) !== -1) {
          allowed = true;
          break;
        }
      }

      if (!allowed) {
        return next('/forbidden');
      }
    }

    return next();
  }
}

const history = createWebHistory(process.env.BASE_URL)

export default function() {
  return createRouter({
    history,
    linkActiveClass: 'is-active',
    routes: [
      // public
      { name: 'start',               path: '/',                component: () => import('@/views/StartView') },
      { name: 'welcome',             path: '/welcome',         component: () => import('@/views/WelcomeView') },
      { name: 'register',            path: '/register',        meta: { title: ['new', 'user'] }, component: () => import('@/views/user/UserRegister') },
      { name: 'login',               path: '/login',           meta: { title: 'login' },         component: () => import('@/views/user/UserLogin') },
      { name: 'logout',              path: '/logout',          meta: { title: 'logout', menuGroup: 'user', menuSort: 901 },        component: () => import('@/views/user/UserLogout'), beforeEnter: authGuard() },
      // sysadmin
      { name: 'taxtable',            path: '/sa/taxtable',     meta: { title: 'tax table', menuGroup: 'sysadmin', menuSort: 9000 }, component: () => import('@/views/sysadmin/TaxTableImport'), beforeEnter: authGuard({ role: 'ADMIN' }) },
      // private
      { name: 'user',                path: '/user',            meta: { title: 'user', menuGroup: 'user', menuSort: 900 }, component: () => import('@/views/user/UserEdit'),          beforeEnter: authGuard() },
      { name: 'company-new',         path: '/company/new',     meta: { title: ['nytt', 'company'] }, component: () => import('@/views/company/CompanyEdit'), beforeEnter: authGuard() },
      { name: 'company-root',        path: '/c/:companyId',    meta: { title: 'company' }, component: () => import('@/views/company/CompanyRoot'), beforeEnter: authGuard(), props: true,
      children: [
        { path: 'transaction', children: [
          { name: 'journal-dashboard',    path: 'dashboard',        meta: {title: 'dashboard', menuGroup: 'accounting', menuSort: 10 }, component: () => import('@/views/journal/JournalDashboard'),   beforeEnter: authGuard({ functions: ['journal_read']  }) },
          { name: 'journal-list',         path: 'list',             meta: {title: 'transactions', menuGroup: 'accounting', menuSort: 11 }, component: () => import('@/views/journal/JournalList'),        beforeEnter: authGuard({ functions: ['journal_read']  }) },
          { name: 'journal-search',       path: 'search',           meta: {title: ['search', 'transactions'] },  component: () => import('@/views/journal/JournalSearch'),      beforeEnter: authGuard({ functions: ['journal_read']  }) },
          { name: 'journal-query',        path: 'query',            meta: {title: 'transactions' },              component: () => import('@/views/journal/JournalQuery'),       beforeEnter: authGuard({ functions: ['journal_read']  }) },
          { name: 'journal-new',          path: 'new',              meta: {title: ['new', 'transaction'], menuGroup: 'accounting', menuSort: 12 }, component: () => import('@/views/journal/JournalEdit'),        beforeEnter: authGuard({ functions: ['journal_write'] }) },
          { name: 'journal-edit',         path: ':id/edit',         meta: {title: ['edit', 'transaction'] },     component: () => import('@/views/journal/JournalEdit'),        beforeEnter: authGuard({ functions: ['journal_write'] }) },
          { name: 'journal-details',      path: ':id',              meta: {title: 'transaction' },               component: () => import('@/views/journal/JournalDetails'),     beforeEnter: authGuard({ functions: ['journal_read']  }) },
        ] },
        { path: 'account', children: [
          { name: 'account-list',         path: 'list',             meta: {title: 'accounts', menuGroup: 'admin', menuSort: 88 }, component: () => import('@/views/account/AccountList'), beforeEnter: authGuard({ functions: ['journal_read']  }) },
          { name: 'account-class-edit',   path: 'class/:id/edit',   meta: {title: ['edit', 'account class'] },   component: () => import('@/views/account/AccountClassEdit'),   beforeEnter: authGuard({ functions: ['journal_admin'] }) },
          { name: 'account-class',        path: 'class/:id',        meta: {title: 'account class' },             component: () => import('@/views/account/AccountClassDetails'),beforeEnter: authGuard({ functions: ['journal_admin'] }) },
          { name: 'account-group-edit',   path: 'group/:id/edit',   meta: {title: ['edit', 'account group'] },   component: () => import('@/views/account/AccountGroupEdit'),   beforeEnter: authGuard({ functions: ['journal_admin'] }) },
          { name: 'account-group',        path: 'group/:id',        meta: {title: 'account group' },             component: () => import('@/views/account/AccountGroupDetails'),beforeEnter: authGuard({ functions: ['journal_admin'] }) },
          { name: 'account-head-edit',    path: 'head/:id/edit',    meta: {title: ['edit', 'main account'] },    component: () => import('@/views/account/AccountHeadEdit'),    beforeEnter: authGuard({ functions: ['journal_admin'] }) },
          { name: 'account-head',         path: 'head/:id',         meta: {title: 'main account' },              component: () => import('@/views/account/AccountHeadDetails'), beforeEnter: authGuard({ functions: ['journal_admin'] }) },
          { name: 'account-new',          path: 'new',              meta: {title: ['nytt', 'account'] },         component: () => import('@/views/account/AccountEdit'),        beforeEnter: authGuard({ functions: ['journal_admin'] }) },
          { name: 'account-edit',         path: ':id/edit',         meta: {title: ['edit', 'account'] },         component: () => import('@/views/account/AccountEdit'),        beforeEnter: authGuard({ functions: ['journal_admin'] }) },
          { name: 'account',              path: ':id',              meta: {title: 'account' },                   component: () => import('@/views/account/AccountDetails'),     beforeEnter: authGuard({ functions: ['journal_admin'] }) },
        ] },
        { path: 'event', children: [
          { name: 'event-list',           path: 'list',               meta: {title: 'events', menuGroup: 'admin', menuSort: 89 },                    component: () => import('@/views/event/EventList'),            beforeEnter: authGuard({ functions: ['journal_read']  }) },
          { name: 'event-new',            path: 'new',                meta: {title: ['new', 'event'] },            component: () => import('@/views/event/EventEdit'),            beforeEnter: authGuard({ functions: ['journal_write'] }) },
          { name: 'event-edit',           path: ':id/edit',           meta: {title: ['edit', 'event'] },           component: () => import('@/views/event/EventEdit'),            beforeEnter: authGuard({ functions: ['journal_write'] }) },
        ] },
        { path: 'report', children: [
          { name: 'result-statement',     path: 'resultstatement',          meta: {title: 'result statement',  menuGroup: 'reports', menuSort: 50 }, component: () => import('@/views/reports/ResultStatement'),    beforeEnter: authGuard({ functions: ['journal_read']  }) },
          { name: 'balance-statement',    path: 'balancestatement',         meta: {title: 'balance statement', menuGroup: 'reports', menuSort: 51 }, component: () => import('@/views/reports/BalanceStatement'),   beforeEnter: authGuard({ functions: ['journal_read']  }) },
          { name: 'journal-rows',         path: 'journalrows',              meta: {title: 'transaction rows',  menuGroup: 'reports', menuSort: 52 }, component: () => import('@/views/reports/JournalRows'),        beforeEnter: authGuard({ functions: ['journal_read']  }) },
        ] },
        { path: 'sie', children: [
         { name: 'sie-export',            path: 'export',               meta: {title: 'sie', menuGroup: 'export', menuSort: 200 }, component: () => import('@/views/reports/SieExport'),          beforeEnter: authGuard({ functions: ['journal_admin'] }) },
        ] },
        { path: 'company', children: [
          { name: 'company-details',      path: 'view',                     meta: {title: ['company'] },                 component: () => import('@/views/company/CompanyDetails'),  beforeEnter: authGuard({ functions: ['ledger_read']   }) },
          { name: 'company-edit',         path: 'edit',                     meta: {title: ['edit', 'company'] },         component: () => import('@/views/company/CompanyEdit'),     beforeEnter: authGuard({ functions: ['ledger_admin']   }) },
          { name: 'company-edit-users',   path: 'users',                    meta: {title: ['edit', 'users'] },           component: () => import('@/views/company/CompanyEditUsers'),     beforeEnter: authGuard({ functions: ['ledger_admin']   }) },
          { name: 'company-edit-logo',    path: 'logo',                     meta: {title: ['edit', 'logo'] },            component: () => import('@/views/company/CompanyEditLogo'),     beforeEnter: authGuard({ functions: ['ledger_admin']   }) },
          { name: 'company-list',         path: 'list',                     meta: {title: 'companies', menuGroup: 'admin', menuSort: 87  }, component: () => import('@/views/company/CompanyList'),   beforeEnter: authGuard({ functions: ['ledger_read']   }) },
          { name: 'email-inbox',          path: 'inbox',                    meta: {title: 'inbox', menuGroup: 'admin', menuSort: 90 },                     component: () => import('@/views/ledger/EmailInbox'),          beforeEnter: authGuard({ functions: ['ledger_admin']  }) },
          { name: 'sie-import',           path: 'sie/import',               meta: {title: 'sie', menuGroup: 'import', menuSort: 800 },                       component: () => import('@/views/ledger/SieImport'),           beforeEnter: authGuard({ functions: ['ledger_admin']  }) },
          { name: 'ver-import',           path: 'ver/import',               meta: {title: 'verifications', menuGroup: 'import', menuSort: 801 },             component: () => import('@/views/ledger/VerificationImport'),  beforeEnter: authGuard({ functions: ['ledger_admin']  }) },
          { name: 'fiscal-years',         path: 'fiscalyears',              meta: {title: 'fiscal years', menuGroup: 'admin', menuSort: 95 },              component: () => import('@/views/ledger/FiscalYearList'),      beforeEnter: authGuard({ functions: ['ledger_read']   }) },
          { name: 'fiscal-year-edit',     path: 'fiscalyear/:id/edit',      meta: {title: 'fiscal year' },               component: () => import('@/views/ledger/FiscalYearEdit'),      beforeEnter: authGuard({ functions: ['ledger_admin']  }) },
          { name: 'fiscal-year-new',      path: 'fiscalyear/new',           meta: {title: 'fiscal year' },               component: () => import('@/views/ledger/FiscalYearEdit'),      beforeEnter: authGuard({ functions: ['ledger_admin']  }) },
        ] },
        { path: 'customer', children: [
          { name: 'customer-new',          path: 'new',                  meta: {title: ['new', 'customer'] },   component: () => import('@/views/customer/CustomerEdit'),    beforeEnter: authGuard({ functions: ['invoice_admin'] }) },
          { name: 'customer-edit',         path: ':id/edit',             meta: {title: ['edit', 'customer'] },  component: () => import('@/views/customer/CustomerEdit'),    beforeEnter: authGuard({ functions: ['invoice_admin'] }) },
          { name: 'customer-details',      path: ':id',                  meta: {title: 'customer' },            component: () => import('@/views/customer/CustomerDetails'), beforeEnter: authGuard({ functions: ['invoice_read']  }) },
          { name: 'customer-list',         path: 'list',                 meta: {title: 'customers', menuGroup: 'admin', menuSort: 89.6 },           component: () => import('@/views/customer/CustomerList'),    beforeEnter: authGuard({ functions: ['invoice_read']  }) },
        ] },
        { path: 'article', children: [
          { name: 'article-list',          path: 'list',          meta: {title: ['articles'], menuGroup: 'admin', menuSort: 89.8 }, component: () => import('@/views/article/ArticleList'), beforeEnter: authGuard({ functions: ['invoice_admin'] }) },
          { name: 'article-details',       path: ':id',        meta: {title: ['article'] }, component: () => import('@/views/article/ArticleDetails'), beforeEnter: authGuard({ functions: ['invoice_admin'] }) },
          { name: 'article-new',           path: 'new',      meta: {title: ['new', 'article'] },  component: () => import('@/views/article/ArticleEdit'), beforeEnter: authGuard({ functions: ['invoice_admin'] }) },
          { name: 'article-edit',          path: ':id/edit', meta: {title: ['edit', 'article'] }, component: () => import('@/views/article/ArticleEdit'), beforeEnter: authGuard({ functions: ['invoice_admin'] }) },
        ] },
        { path: 'invoice', children: [
          { name: 'invoice-dashboard',          path: 'dashboard',                meta: {title: 'dashboard', menuGroup: 'invoice', menuSort: 71 }, component: () => import('@/views/invoice/InvoiceDashboard'),    beforeEnter: authGuard({ functions: ['invoice_read']  }) },
          { name: 'invoice-new',                path: 'new',                      meta: {title: ['new', 'invoice'],  menuGroup: 'invoice', menuSort: 73 }, component: () => import('@/views/invoice/InvoiceEdit'), beforeEnter: authGuard({ functions: ['invoice_write'] }) },
          { name: 'invoice-edit',               path: ':id/edit',                 meta: {title: ['edit', 'invoice'] }, component: () => import('@/views/invoice/InvoiceEdit'),     beforeEnter: authGuard({ functions: ['invoice_write'] }) },
          { name: 'invoice-register-payment',   path: 'register-payment',         meta: {title: 'register payments' }, component: () => import('@/views/invoice/RegisterPayment'), beforeEnter: authGuard({ functions: ['invoice_write'] }) },
          { name: 'invoice-details',            path: ':id',                      meta: {title: 'invoice' },           component: () => import('@/views/invoice/InvoiceDetails'),  beforeEnter: authGuard({ functions: ['invoice_read']  }) },
        ] },   
        { path: 'salary', children: [
          { name: 'salary-statement-new',     path: 'new',       meta: {title: ['create', 'pay slip'] },  component: () => import('@/views/salary/SalaryStatementNew'), beforeEnter: authGuard({ functions: ['salary_admin'] }) },
          { name: 'salary-statement-edit',    path: ':id/edit',  meta: {title: ['edit', 'pay slip'] },  component: () => import('@/views/salary/SalaryStatementEdit'), beforeEnter: authGuard({ functions: ['salary_admin'] }) },
          { name: 'salary-dashboard',         path: 'dashboard', meta: {title: 'dashboard', menuGroup: 'salary', menuSort: 81 },  component: () => import('@/views/salary/SalaryDashboard'), beforeEnter: authGuard({ functions: ['salary_admin'] }) },
          { name: 'salary-statement-details', path: ':id',       meta: {title: 'pay slip' },              component: () => import('@/views/salary/SalaryStatementDetails'), beforeEnter: authGuard({ functions: ['salary_admin'] }) },
        ] },
        { path: 'employee', children: [
          { name: 'employee-list',      path: 'list',     meta: {title: ['employees'], menuGroup: 'admin', menuSort: 94 }, component: () => import('@/views/employee/EmployeeList'), beforeEnter: authGuard({ functions: ['salary_admin'] }) },
          { name: 'employee-new',       path: 'new',      meta: {title: ['new', 'employee'] },  component: () => import('@/views/employee/EmployeeEdit'),    beforeEnter: authGuard({ functions: ['salary_admin'] }) },
          { name: 'employee-edit',      path: ':id/edit', meta: {title: ['edit', 'employee'] }, component: () => import('@/views/employee/EmployeeEdit'),    beforeEnter: authGuard({ functions: ['salary_admin'] }) },
          { name: 'employee-details',   path: ':id',      meta: {title: 'employee' },           component: () => import('@/views/employee/EmployeeDetails'), beforeEnter: authGuard({ functions: ['salary_admin'] }) },
        ] },
        { path: 'project', children: [
          { name: 'project-list', path: 'list', meta: { title: ['projects'], menuGroup: 'admin', menuSort: 205 }, component: () => import('@/views/project/ProjectList'), beforeEnter: authGuard({ }) },
          { name: 'project-details', path: ':id', meta: { title: ['project'] }, component: () => import('@/views/project/ProjectDetails'), beforeEnter: authGuard({ }) },
          { name: 'project-edit', path: ':id/edit', meta: { title: ['edit', 'project'] }, component: () => import('@/views/project/ProjectEdit'), beforeEnter: authGuard({ }) },
          { name: 'project-new', path: 'new', meta: { title: ['new', 'project'] }, component: () => import('@/views/project/ProjectEdit'), beforeEnter: authGuard({ }) },
        ] },
        { path: 'worklog', children: [
          { name: 'worklog-dashboard',     path: 'dashboard',    meta: {title: ['dashboard'], menuGroup: 'log', menuSort: 81 }, component: () => import('@/views/worklog/WorklogDashboard'), beforeEnter: authGuard({ }) },
          { name: 'worklog-new',           path: 'new',          meta: {title: ['new', 'worklog'] },    component: () => import('@/views/worklog/WorklogEdit'),      beforeEnter: authGuard({ }) },
          { name: 'worklog-list',          path: 'list',         meta: {title: 'worklog', menuGroup: 'log', menuSort: 82 }, component: () => import('@/views/worklog/WorklogList'), beforeEnter: authGuard({ }) },
          { name: 'worklog-calendar',      path: 'calendar',     meta: {title: 'worklog' },             component: () => import('@/views/worklog/WorklogCalendar'),  beforeEnter: authGuard({ }) },
          { name: 'worklog-details',       path: ':id',          meta: {title: 'worklog' },             component: () => import('@/views/worklog/WorklogDetails'),   beforeEnter: authGuard({ }) },
          { name: 'worklog-edit',          path: ':id/edit',     meta: {title: ['edit', 'worklog'] },   component: () => import('@/views/worklog/WorklogEdit'),      beforeEnter: authGuard({ }) },
        ] },
        { path: 'driverlog', children: [
          { name: 'driver-log-list',         path: 'list',            meta: {title: 'driver log', menuGroup: 'log', menuSort: 120},           component: () => import('@/views/driverlog/DriverLogList'),    beforeEnter: authGuard({ }) },
          { name: 'driver-log-details',      path: ':id',             meta: {title: 'driver log' },           component: () => import('@/views/driverlog/DriverLogDetails'), beforeEnter: authGuard({ }) },
          { name: 'driver-log-new',          path: 'new',             meta: {title: ['new', 'driver log'] },  component: () => import('@/views/driverlog/DriverLogEdit'),    beforeEnter: authGuard({ }) },
          { name: 'driver-log-edit',         path: ':id/edit',        meta: {title: ['edit', 'driver log'] }, component: () => import('@/views/driverlog/DriverLogEdit'),    beforeEnter: authGuard({ }) },
          { name: 'driver-route-edit',       path: 'route/:id',       meta: {title: 'route' },                component: () => import('@/views/driverlog/RouteEdit'),       beforeEnter: authGuard({ }) },
          { name: 'driver-destination-edit', path: 'destination/:id', meta: {title: 'destination' },          component: () => import('@/views/driverlog/DestinationEdit'), beforeEnter: authGuard({ }) },
        ] },
        { path: 'driverstatement', children: [
          { name: 'driver-statement-new',     path: 'new', meta: {title: ['new', 'driver statement'] }, component: () => import('@/views/driverstatement/DriverStatementNew'),     beforeEnter: authGuard({ }) },
          { name: 'driver-statement-details', path: ':id', meta: {title: ['driver statement'] },        component: () => import('@/views/driverstatement/DriverStatementDetails'), beforeEnter: authGuard({ }) },
         ] },
      ].map(item => {
        let child = { ...item, props: true, beforeEnter: authGuard() };
        if (child.children) child.children = child.children.map(item => {
          return { ...item, props: true }
        });
        return child;
      })
      },
      // other
      { name: 'forbidden',      path: '/forbidden',       component: () => import('@/views/ErrorForbidden') },
      { name: 'page-not-found', path: '/not-found',       component: () => import('@/views/ErrorNotFound') },
      { name: 'page-not-found', path: '/:pathMatch(.*)*', component: () => import('@/views/ErrorNotFound') }
    ]
  })
}
