import { DateTime } from "luxon"

// initial state
const state = {
  reportDate: localStorage.getItem('report-date'),
  reportDateUnit: localStorage.getItem('report-date-unit'),
  resultStatementSorting: { }
}

// getters
const getters = {
  reportDate: state => state.reportDate || DateTime.now().startOf('month').toISODate(),
  reportDateUnit: state => state.reportDateUnit || 'month',
  resultStatementSorting: state => {
    var sorting = {}
    for (var key in state.resultStatementSorting) {
      sorting[key] = []
      for (var key2 in state.resultStatementSorting[key]) {
        sorting[key].push(state.resultStatementSorting[key][key2])
      }
    }
    return sorting
  }
}

// actions
const actions = {
  setReportDate ({ commit }, date) {
    commit('setReportDate', { date })
  },
  setReportDateUnit ({ commit }, unit) {
    commit('setReportDateUnit', { unit })
  }
}

// mutations
const mutations = {
  setReportDate (state, { date }) {
    var d = DateTime.fromISO(date).toISODate()
    state.reportDate = d
    localStorage.setItem('report-date', d)
  },
  setReportDateUnit (state, { unit }) {
    state.reportDateUnit = unit
    localStorage.setItem('report-date-unit', unit)
  },
  setResultStatementSorting (state, { sorting } ) {
    for (var key in sorting) {
      state.resultStatementSorting[key] = []
      for (var key2 in sorting[key]) {
        state.resultStatementSorting[key].push(sorting[key][key2])
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
