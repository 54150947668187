export const sv = {
    name: 'namn',
    unit: 'enhet',
    price: 'pris',
    vatRate: 'moms',
}

export const en = {
    ...Object.fromEntries(Object.entries(sv).map(item => [item[0], item[0].replace(/[A-Z]+/g, str => ` ${str.toLowerCase()}`)])),
}
